import React from 'react'
import PropTypes from 'prop-types'


import AniLink from 'gatsby-plugin-transition-link/AniLink'
import InViewMonitor from 'react-inview-monitor'


class HomepageWhoWeAre extends React.Component {
  render() {
    const { whowearehome } = this.props


    return (
      <div className="whoWeAreTitle">
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeInDown animDelay2"
        >
          <h1>Who we are</h1>
        </InViewMonitor>
        <div className="whoWeAreHomepageTextGrid">
          <div className="topLeftText">
            <InViewMonitor
              classNameNotInView="vis-hidden"
              classNameInView="animated fadeIn animDelay1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: whowearehome.whowearesectionone,
                }}
              />
            </InViewMonitor>
          </div>
          <div className="bottomRightText">
            <InViewMonitor
              classNameNotInView="vis-hidden"
              classNameInView="animated fadeIn animDelay3"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: whowearehome.whowearesectiontwo,
                }}
              />
              <br />
              <AniLink
                to="/about-us"
                fade
                className="catButton blockButton noLeftPadding"
              >
                FIND OUT MORE
              </AniLink>
            </InViewMonitor>
          </div>
        </div>
      </div>
    )
  }
}

HomepageWhoWeAre.propTypes = {
  whowearehome: PropTypes.object,
}

export default HomepageWhoWeAre
