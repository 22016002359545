import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link'
import InViewMonitor from 'react-inview-monitor'
// import { graphql } from 'gatsby'

class CreateHeroHomepage extends Component {
  render() {
    const { homepageIntroText } = this.props


    return (
      <div className="heroHolder">
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeInDown animDelay1"
        >
          <div className="leftHeroText">
            <h1>INTERIOR DESIGN</h1>
          </div>
        </InViewMonitor>

        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeInDown animDelay2"
        >
          <div className="rightHeroText">
            <h1>STUDIO</h1>
          </div>
        </InViewMonitor>
        <div className="introHeroText">
          <InViewMonitor
            classNameNotInView="vis-hidden"
            classNameInView="animated fadeIn animDelay3"
          >
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: homepageIntroText.homepageintro,
                }}
              />

              <AniLink
                to="/about-us"
                className="catButton blockButton noLeftPadding"
              >
                Contact us
              </AniLink>
            </div>
          </InViewMonitor>
        </div>
      </div>
    )
  }
}

CreateHeroHomepage.propTypes = {
  homepageIntroText: PropTypes.object,
}

export default CreateHeroHomepage
