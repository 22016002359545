import React from 'react'
import PropTypes, { array } from 'prop-types'

import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import InViewMonitor from 'react-inview-monitor'
// import ImagesSvg from '../img/images.svg'

function getSelectedProjectElements(someElemz) {
  const n = 4
  const shuffeled = someElemz.sort(() => 0.5 - Math.random())
  const selected = shuffeled.slice(0, n)
  return selected
}

const VerticalElement = styled.div`
  background: url(${props => props.img || 'red'});
  height: 80vh;
  border: none;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

class VerticalProjects extends React.Component {
  constructor(props) {
    super()

    this.newShuffeled = []
    const selectedElementsArray = props.featuredProjects

    this.state = {
      width: props.width,
      fullElements: props.featuredProjects,
    }
  }

  componentWillMount() {
    // console.log('mounted')
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)

    const leListOfElements = this.props.featuredProjects
    const newShuffeled = getSelectedProjectElements(leListOfElements)
    setTimeout(() => {
      this.setState({ fullElements: newShuffeled })
    }, 1000)

    this.resize()
  }

  componentDidUpdate() {
    // const leListOfElements = this.props.featuredProjects
    // const newShuffeled = getSelectedProjectElements(leListOfElements)
    // console.log('did update')
    // console.log(newShuffeled)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  resize = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { width, fullElements, newShuffeled } = this.state
    const { projectElements, featuredProjects } = this.props

    const isMobile = width <= 768



    // console.log(this.state)
    // console.log('render')
    // console.log(fullElements)

    return (
      <div className="verticalGridElementsHolder">
        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeInUp"
        >
          <div className="hp_vertical_grid_div post transition">
            {fullElements.map(( node , index) => {

				// console.log('node');
				// console.log(node.project.acfBeforeAfterImages.imageafterelement.localFile.childImageSharp.original.src);
              const firstImage =
			  node.project.acfBeforeAfterImages.imageafterelement.localFile.childImageSharp.original.src
              //   const firstImage =
              //     (index + 1) % 2 === 1
              //       ? node.acf.imageAfterElement.localFile.childImageSharp.fluid
              //           .originalImg
              //       : node.acf.imageBeforeElement.localFile.childImageSharp.fluid
              //           .originalImg

            //   const secondImage =
            //     (index + 1) % 2 === 1
            //       ? node.acf.imageBeforeElement.localFile.childImageSharp.fluid
            //           .originalImg
            //       : node.acf.imageAfterElement.localFile.childImageSharp.fluid
            //           .originalImg

              return (
                <AniLink key={node.project.slug} to={`/projects/${node.project.slug}`} fade>
                  <div
                    key={node.project.slug}
                    className="verticalImage post transition"
                  >
                    <VerticalElement
                      className="verticallia"
                      key={node.project.slug}
                      style={{ backgroundImage: `url(${firstImage})` }}
                    />
                  </div>
                </AniLink>
              )
            })}
          </div>
        </InViewMonitor>

        <InViewMonitor
          classNameNotInView="vis-hidden"
          classNameInView="animated fadeIn"
        >
          <div className="projectsTextColored">
            <h1>
              <span>PROJECTS</span>
            </h1>
          </div>
        </InViewMonitor>

        <div className="linkElementToProjects">
          <AniLink
            className="linkToProjectList catButton blockButton "
            to="/projects"
            fade
          >
            FIND OUT MORE
          </AniLink>
        </div>
      </div>
    )
  }
}

VerticalProjects.propTypes = {
  projectElements: PropTypes.arrayOf(PropTypes.object),
  featuredProjects: PropTypes.arrayOf(PropTypes.object),
}
export default VerticalProjects
